<!-- =========================================================================================
    File Name: KnowledgeBase.vue
    Description: Knowledge Base Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="knowledge-base-page">
    <!-- JUMBOTRON -->
    <div class="knowledge-base-jumbotron">
      <div
        class="knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base"
      >
        <h1 class="text-white mb-4">
          <strong
            >{{
              $t(resources.FeaturedNews.i18n) || resources.FeaturedNews.name
            }}
          </strong>
        </h1>
        <div class="vx-row">
          <div
            class="vx-col w-full lg:w-1/4 md:w-1/2 sm:w-full mb-base"
            v-for="index in featuredList"
            :key="index.id"
          >
            <vx-card class="text-center cursor-pointer">
              <iframe
                v-if="index.attachmentType == 'MP4'"
                width="150"
                :src="index.attachementUrl"
                frameborder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <img
                v-else
                :src="index.attachementUrl"
                width="200"
                height="220"
                alt="graphic"
                class="responsive mx-auto mb-4"
              />
              <h4 class="mb-2">
                <strong>{{ index.title }}</strong>
              </h4>
              <div
                v-html="`${index.body.replace('<p>', '').substring(0, 75)} ...`"
              ></div>
            </vx-card>
          </div>
        </div>
      </div>
    </div>

    <!-- KNOWLEDGE BASE CARDS  -->
    <h1 class="text-primary mb-4">
      <strong
        >{{ $t(resources.Categories.i18n) || resources.Categories.name }}
      </strong>
    </h1>
    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in categories"
        :key="item.id"
        @click="goToContents(item.id)"
      >
        <vx-card class="text-center cursor-pointer">
          <img :src="item.imageUrl" alt="graphic" class="responsive mx-auto mb-4" />
          <h4 class="mb-2">
            <strong>{{ item.name.toUpperCase() }}</strong>
          </h4>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import axios from "axios";

export default {
  data() {
    return {
      resources: resources,
      featuredList: [],
      categories: []
    };
  },
  computed: {},
  async created() {
    await this.get();
    await this.getContenType();
  },
  methods: {
    async get() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Blog/Search`,
        data: {
          searchKeys: {
            blogTypeId: 2,
            isFeatured: true
          },
          page: 1,
          rows: 4,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.featuredList = [];
          } else {
            this.featuredList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getContenType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ContentType/GetByType/2/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.categories = [];
          } else {
            this.categories = result.data.filter(x => x.id !== 0);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    goToContents(contentTypeId) {
      this.$router.push({
        name: "resources-news-list",
        params: {
          contentTypeId: contentTypeId
        }
      });
    }
  }
};
</script>

<style lang="scss">
.knowledge-base-jumbotron-content {
  background-image: url("../../assets/images/pages/knowledge-base-cover.jpg");
  background-size: cover;
}
</style>
