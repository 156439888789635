var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "knowledge-base-page" } }, [
    _c("div", { staticClass: "knowledge-base-jumbotron" }, [
      _c(
        "div",
        {
          staticClass:
            "knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base"
        },
        [
          _c("h1", { staticClass: "text-white mb-4" }, [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.FeaturedNews.i18n) ||
                    _vm.resources.FeaturedNews.name
                ) + "\n        "
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "vx-row" },
            _vm._l(_vm.featuredList, function(index) {
              return _c(
                "div",
                {
                  key: index.id,
                  staticClass:
                    "vx-col w-full lg:w-1/4 md:w-1/2 sm:w-full mb-base"
                },
                [
                  _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
                    index.attachmentType == "MP4"
                      ? _c("iframe", {
                          attrs: {
                            width: "150",
                            src: index.attachementUrl,
                            frameborder: "0",
                            allow:
                              "accelerometer; encrypted-media; gyroscope; picture-in-picture",
                            allowfullscreen: ""
                          }
                        })
                      : _c("img", {
                          staticClass: "responsive mx-auto mb-4",
                          attrs: {
                            src: index.attachementUrl,
                            width: "200",
                            height: "220",
                            alt: "graphic"
                          }
                        }),
                    _c("h4", { staticClass: "mb-2" }, [
                      _c("strong", [_vm._v(_vm._s(index.title))])
                    ]),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          index.body.replace("<p>", "").substring(0, 75) +
                            " ..."
                        )
                      }
                    })
                  ])
                ],
                1
              )
            }),
            0
          )
        ]
      )
    ]),
    _c("h1", { staticClass: "text-primary mb-4" }, [
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.$t(_vm.resources.Categories.i18n) ||
              _vm.resources.Categories.name
          ) + "\n    "
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "vx-row" },
      _vm._l(_vm.categories, function(item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "vx-col w-full md:w-1/3 sm:w-1/2 mb-base",
            on: {
              click: function($event) {
                return _vm.goToContents(item.id)
              }
            }
          },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("img", {
                staticClass: "responsive mx-auto mb-4",
                attrs: { src: item.imageUrl, alt: "graphic" }
              }),
              _c("h4", { staticClass: "mb-2" }, [
                _c("strong", [_vm._v(_vm._s(item.name.toUpperCase()))])
              ])
            ])
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }